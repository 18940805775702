import Resources from '@src/@types/resources'

export type NormsMessageKey = keyof Resources['translation']['CreditDecisionNorms']

export enum ECarCondition {
  New = 'new',
  Used = 'used',
}

export const ECarConditionList = Object.values(ECarCondition)

export enum EHomeStatus {
  Owner = 'own',
  Renter = 'rent',
  OwnMobileHome = 'ownMobileHome',
  WithParents = 'withParents',
  ReserveHousing = 'reserveHousing',
  OwnWithMortgage = 'ownWithMortgage',
  Other = 'other',
}

export const EHomeStatusList = Object.values(EHomeStatus)

export enum ECountry {
  Canada = 'Canada',
}

export enum ETasksStatus {
  Completed = 'completed',
  Cancelled = 'cancelled',
  ToDo = 'todo',
}

export const ETasksStatusList = Object.values(ETasksStatus)

export enum EEntryType {
  Credit = 'credit',
  Funding = 'funding',
  DocumentsAndIncomes = 'documentsAndIncomes',
  Financing = 'financing',
}
export enum EFinancingCompany {
  IFinance = 'IFinance',
  Iceberg = 'Iceberg',
}

export enum EIFinanceTeam {
  IFinance = 'iFinance',
  Medicard = 'medicard',
}

export const EEntryTypeList = Object.values(EEntryType)

export enum ECreditTaskDashboardEntryAction {
  TaskAction = 'TaskAction',
  ConfirmAllIncomesAction = 'ConfirmAllIncomesAction',
  DocumentAction = 'DocumentAction',
  CreditDecisionAction = 'CreditDecisionAction',
  WorksheetDecisionAction = 'WorksheetDecisionAction',
  CreateLoanAction = 'CreateLoanAction',
  CreateWorksheetAction = 'CreateWorksheetAction',
}

export enum EAssignmentFilter {
  Blank = 'blank',
  AssignedApplication = '0',
  UnassignedApplication = '1',
  MyApplicationsOnly = '2',
  MyApplicationsAndUnassignedOnes = '3',
}

export const EAssignmentFilterList = Object.values(EAssignmentFilter)

export enum EDocumentStatus {
  AwaitingDocument = 'awaitingDocument',
  AwaitingScan = 'awaitingScan',
  AwaitingApproval = 'awaitingApproval',
  Incomplete = 'incomplete',
  Deleted = 'deleted',
  Refused = 'refused',
  Approved = 'approved',
}

export const EDocumentStatusList = Object.values(EDocumentStatus).filter(
  (item) => item !== EDocumentStatus.AwaitingDocument && item !== EDocumentStatus.AwaitingScan,
)

export enum EDocumentPageStatus {
  New = 'new',
  Reviewed = 'reviewed',
  Deleted = 'deleted',
}

export const EDocumentPageStatusList = Object.values(EDocumentPageStatus)

export enum EApplicantType {
  Applicant = 'applicant',
  CoApplicant = 'coApplicant',
}

export const EApplicantTypeList = Object.values(EApplicantType)

export enum ETaskType {
  RegisterToRDPRM = 0,
  VerifyEmployment = 3,
  VerifyDocument = 4,
  OtherTask = 5,
  VerifyMultipleEquifaxFiles = 6,
  VerifyDateOfBirth = 7,
  VerifySin = 8,
  ReviewCreditFile = 9,
  ReviewWorksheet = 10,
  AnalyzeCredit = 11,
  VerifyBankruptcyHistory = 12,
  ConfirmOtherIncome = 13,
  VerifyStolenOrLostIdentity = 14,
  VerifyAddressMisuse = 15,
  VerifyFraudAlert = 16,
  ValidateClientIdentity = 20,
  VerifyCar = 21,
  VerifyCity = 22,
  ConfirmJobIncome = 23,
  ConfirmAllIncomes = 24,
  VerifyWorkPermit = 25,
  DetermineIfRefinancing = 26,
  RemindIdentityVerification = 27,
  ConfirmActivationDateWithClient = 28,
  ConfirmDetailsWithServiceProvider = 29,
  VerifyAccountHolderName = 30,
  InformClientGrantedAmountLowerThanRequested = 31,
  ValidateMerchant = 32,
  CreateLoan = 33,
  ContactSupportForInvestigation = 34,
  ContactCoApplicantForAuthorization = 35,
  CompleteWorksheet = 36,
  VerifyWhyCvtSignatureWasDeclined = 37,
  ContactClientToFinalizeApplication = 38,
  FlinksIncomeApprovalFollowUp = 39,
  ValidateVoidCheque = 40,
  ContactClientFollowingCancellation = 41,
  CreateWorksheet = 42,
  ApproveWorksheet = 43,
  MissingDocument = 44,
  VerifyBankStatements = 45,
  VerifyAddress = 46,
  FillBankAccountInformation = 47,
}

export const ETaskTypeList = Object.values(ETaskType).filter((x) => typeof x === 'number')

export enum EDocumentType {
  AlimonyCourtJudgment = 0,
  BandCouncilCard = 2,
  BankruptcyTrusteeForm = 3,
  Bill134FormAndCreditBureau = 4,
  BorrowerAutoInsuranceProofMentioningIceberg = 5,
  CarfaxLessThanMonthsAndLessThanAmount = 6,
  CompletedAndSignedCreditApplication = 7,
  ContractForOtherInsuranceProductsFinanced = 8,
  DealerRecognitionProgramAppendix = 9,
  DealerResponsibilityLetter = 10,
  DebtRatioAppendix = 11,
  ElectronicSignatureAudit = 12,
  EmployerForm = 13,
  LetterOfEmployment = 14,
  ExtendedWarrantyContract = 15,
  IcebergContract = 16,
  LastPayStubJob = 17,
  LastPayStubsJobTwo = 18,
  LienReleaseFinancedVehicleOne = 19,
  LienReleaseFinancedVehicleTwo = 20,
  MaternityLeaveBenefit = 21,
  Note = 22,
  ProofOfResidence = 24,
  ProofOfBenefits = 25,
  ProofOfInsolvancyCompletion = 27,
  ProofOfRelease = 28,
  ProofOfSourceOfFunds = 29,
  ProofOfVehicleRegistration = 30,
  References = 31,
  SecondIdWithPhoto = 33,
  SignedWindowSticker = 34,
  SinCardOrOfficialDocumentWithSin = 35,
  SixMonthsPersonalBankStatements = 36,
  T4 = 37,
  T4E = 38,
  ThreeMonthsPersonalBankStatements = 39,
  Transit = 40,
  ValidDriverLicense = 41,
  VehiclePurchaseContract = 42,
  VehicleValueEvaluation = 43,
  VoidCheque = 44,
  WorkPermit = 45,
  Other = 46,
  SignedCVT = 47,
  LessThanThreeMonthsPersonalBankStatements = 48,
  AuditCVT = 49,
  AuthorizationForm = 50,
  PreAuthorizedDebitForm = 51,
  ClinicInvoice = 52,
  ChildTaxBenefitStatement = 53,
  EmploymentInsuranceStatement = 54,
  EmploymentContract = 55,
  ExtraPaymentAuthorization = 56,
  InvoiceForPersonalService = 57,
  NoticeOfAssessment = 58,
  ProofOfIncome = 59,
  ProofOfPayment = 60,
  RentalAgreement = 61,
  RecordOfEmployment = 62,
  T1 = 63,
  T5 = 64,
  UtilityBill = 65,
  EquifaxOrTransUnionNotice = 66,
  ValidPhotoId = 67,
  FamilyAllocationsProof = 68,
  WorkIncidentIndemnisationProof = 69,
  MaternityPaternityIncomeProof = 70,
  OtherIncomeProof = 71,
  RetirementIncomeProof = 72,
  DisabilityIncomeProof = 73,
  AssistanceProof = 74,
  EmployementInsuranceProof = 75,
}

export const EDocumentTypeList = Object.values(EDocumentType).filter((x) => typeof x === 'number')

export enum ECreditApplicationStep {
  Financing = 'financing',
  Credit = 'credit',
  PayOut = 'payout',
  CVT = 'CVT',
}

export const ECreditApplicationStepList = Object.values(ECreditApplicationStep)

export enum EGender {
  men = 0,
  woman = 1,
  other = 2,
}

export const EGenderList = Object.values(EGender).filter((x) => typeof x === 'number')

export enum EJobType {
  fullTime = 0,
  partTime = 1,
  Seasonal = 3,
  SelfEmployed = 5,
}

export enum ELanguage {
  french = 0,
  english = 1,
}
export const ELanguageList = Object.values(ELanguage).filter((x) => typeof x === 'number')

export enum EIndustryCode {
  FarmImplemetDealers = 'AF',
  TruckDealers = 'AL',
  AutomobileDealersNew = 'AN',
  AutomotiveParts = 'AP',
  AutomotiveRepairBodyShops = 'AR',
  ServiceStation = 'AS',
  TBAStoresTireDealers = 'AT',
  AutomobileDealersUsed = 'AU',
  AutomobileMiscellaneous = 'AZ',
  Finance = 'F',
  AutoFinancing = 'FA',
  CreditUnionsFC = 'FC',
  CreditUnionsFD = 'FD',
  SalesFinancing = 'FF',
  Mortgage = 'FM',
  PersonalLoans = 'FP',
  SavingsLoansAssns = 'FS',
  FinanceMiscellaneous = 'FZ',
  Banks = 'B',
  Clothing = 'C',
  DepartmentVariety = 'D',
  Groceries = 'G',
  HomeFernishings = 'H',
  Insurance = 'I',
  JewelryCameras = 'J',
  Contractors = 'K',
  LumberBldgMaterialHardware = 'L',
  MedicalHealth = 'M',
  OilNationalCreditCards = 'O',
  PersonalServices = 'P',
  RealEstateHotelsEtc = 'R',
  SportingGoods = 'S',
  FarmGardenSupply = 'T',
  UtilitiesFuel = 'U',
  Goverment = 'V',
  Wholesale = 'W',
  Advertising = 'X',
  CollectionServices = 'Y',
  MisCellnaneous = 'Z',
}

export enum EOtherNameCode {
  AlsoKnownAs = 'AK',
  FormerName = 'FN',
  LegalNameChange = 'L',
  CrossReference = 'C',
}

export enum EOtherNameSuffixCode {
  Senior = 'SR',
  Junior = 'JR',
  First = '1',
  Second = '2',
  Third = '3',
  Fourth = '4',
  Fifth = '5',
  Sixth = '6',
  Seventh = '7',
  Eight = '8',
  Ninth = '9',
  CombinedFiles = 'XX',
}

export enum EFolioCode {
  Installment = 'I',
  Revolving = 'R',
  Mortgage = 'M',
  OpenAccount = 'O',
  LineOfCredit = 'C',
}

export enum EAssociationCode {
  Individual = 'I',
  Joint = 'J',
  Undesignated = 'U',
}

export enum EPaymentRateCode {
  TooNewToRate = '0',
  PaysWithin30DaysOr1PaymentPastDue = '1',
  PaysBetween30And60DaysOr2PaymentsPastDue = '2',
  PaysBetween60And90DaysOr3PaymentsPastDue = '3',
  PaysBetween90And120DaysOr4PaymentsPastDue = '4',
  Overdue120DaysAndNotYetRated9 = '5',
  RegularPaymentsUnderConsolidationOrderOrSimilar = '7',
  Repossession = '8',
  BadDebt = '9',
}

export enum EPaymentFrequencyCode {
  Monthly = '01',
  BiWeekly = '02',
  Weekly = '03',
  SemiMonthly = '04',
  InterestPaymentOnly = '06',
  BiMonthly = '07',
  SinglePaymentLoan = '08',
  FixedPaymentAmount = '09',
  Quartely = '10',
  Annually = '11',
  SemiAnnually = '12',
  PortionOfBalanceMayRevolve = '13',
}

export enum EPaymentFrequency {
  Monthly = 'monthly',
  BiWeekly = 'biWeekly',
}
export const EPaymentFrequencyList = Object.values(EPaymentFrequency)

export enum EGapInsuranceDuration {
  lessThanSixtyMonths = 0,
  sixtyMonthsOrMore = 1,
  unknown = -1,
}

export enum EExtendedServiceDuration {
  twelveMonths = 12,
  twentyFourMonths = 24,
  thirtySixMonth = 36,
  unknown = 0,
}

export const EExtendedServiceDurationList = Object.values(EExtendedServiceDuration).filter((x) => typeof x === 'number')

export enum EMaritalStatus {
  single = 0,
  married = 1,
  deFactoCouple = 2,
  widowWidower = 3,
  undefined = 999,
}

export const EMaritalStatusList = Object.values(EMaritalStatus).filter((x) => typeof x === 'number')

export enum EOtherIncomeType {
  familyAllocations = 1,
  workIncidentIndemnisation = 3,
  maternityPaternityIncome = 4,
  other = 6,
  retirementIncome = 8,
  disabilityIncome = 11,
  assistance = 13,
  employmentInsurance = 14,
}

export const EOtherIncomeTypeList = Object.values(EOtherIncomeType).filter((x) => typeof x === 'number')

export enum EProvince {
  quebec = 'QC',
  ontario = 'ON',
  novaScotia = 'NS',
  newBrunswick = 'NB',
  princeEdwardIsland = 'PE',
  newfoundland = 'NL',
  britishColombia = 'BC',
  saskatchewan = 'SK',
  alberta = 'AB',
  manitoba = 'MB',
  yukon = 'YT',
  nunavut = 'NU',
  northwestTerritories = 'NT',
}

export const EProvinceList = Object.values(EProvince)

export enum ETerritoriesId {
  AMVOQ = 'monolith*1',
  MontrealAndSouthShore = 'monolith*2',
  NorthernQuebec = 'monolith*3',
  Estrie = 'monolith*4',
  SouthernQuebec = 'monolith*5',
  LanaudiereAndHautesLaurentides = 'monolith*6',
  LavalAndBassesLaurentides = 'monolith*7',
  IFXPRESSMtl = 'monolith*8',
  IFXPRESSQc = 'monolith*9',
  IFXPRESSWeb = 'monolith*10',
  OntarioGTAWest = 'monolith*11',
  OntarioGTACenter = 'monolith*12',
  OntarioEastern = 'monolith*13',
  OntarioSouthEast = 'monolith*14',
  NewBrunswickAndNewFoundland = 'monolith*15',
  NovaScotiaAndPEI = 'monolith*16',
  CanadianFinancial = 'monolith*20',
  IFinance = 'monolith*21',
}

export enum EFinancingProgram {
  Auto = 'auto',
  IfXpress = 'ifxpress',
  Vlvr = 'vlvr',
  Products = 'products',
  Personal = 'personal',
  B2c = 'b2c',
  AutoRsla = 'auto-rsla',
}
export const EFinancingProgramList = Object.values(EFinancingProgram)

export enum EPlanType {
  gold = 'autoGold',
  silver = 'autoSilver',
  bronze = 'autoBronze',
  goldFs = 'autoGoldFS',
  bronzeFs = 'autoBronzeFS',
}

export enum ETermMax {
  six = 6,
  twelve = 12,
  eighteen = 18,
  twentyFour = 24,
  thirty = 30,
  thirtySix = 36,
  fortyTwo = 42,
  fortyEight = 48,
  fiftyFour = 54,
  sixty = 60,
  sixtySix = 66,
  seventyTwo = 72,
  seventyEight = 78,
  eightyFour = 84,
}

export enum EDecision {
  pending = 'pending',
  accept = 'approved',
  manualRevision = 'manual-revision',
  declined = 'refused',
  error = 'error',
}
export const SELECTED_COMPANY_LOCAL_STORAGE = 'selectedCompany'
export const EDecisionList = Object.values(EDecision)

export enum ECreditApplicationStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Expired = 'expired',
}

export const ECreditApplicationStatusList = Object.values(ECreditApplicationStatus)

export enum ETax {
  gst = 'gst',
  pst = 'pst',
  hst = 'hst',
  qst = 'qst',
}

export enum EMask {
  phoneNumberMask = '000-000-0000',
  sinMask = '000 000 000',
  postalCode = 'a0a 0a0',
}

export enum EBankruptcyTypes {
  OngoingBankruptcy = 'F',
  BankruptcyReleased = 'A',
  OngoingConsumerProposal = 'G',
  ConsumerProposalReleased = 'J',
}

export const EBankruptcyTypesList = Object.values(EBankruptcyTypes)

export enum EWorksheetStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
}

export const EWorksheetStatusList = Object.values(EWorksheetStatus)

export enum EWorksheetDecision {
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined',
  ManualRevision = 'manual-revision',
  ApprovedWithCondition = 'approvedWithCondition',
}

export const EWorksheetDecisionList = Object.values(EWorksheetDecision)

export enum EEntityType {
  CreditApplication = 'creditApplication',
}

export enum EBankStatementsRequiredFor {
  Blank = '',
  Applicant = 'applicant',
  Coapplicant = 'coApplicant',
  Both = 'both',
}

export enum ERotationType {
  UPRIGHT = 0,
  QUARTERTURN = 90,
  UPSIDEDOWN = 180,
  THREEQUARTERTURN = 270,
}

export enum EExistingCarLoanStatus {
  ApplicantsCurrentlyHaveNoVehicles = 'applicantsCurrentlyHaveNoVehicles',
  OnlyCoApplicantAlreadyHasVehicle = 'onlyCoApplicantAlreadyHasVehicle',
  OnlyApplicantAlreadyHasVehicle = 'onlyApplicantAlreadyHasVehicle',
  ApplicantsCurrentlyHaveVehicleJointly = 'applicantsCurrentlyHaveVehicleJointly',
}

export const EExistingCarLoanStatusList = Object.values(EExistingCarLoanStatus)

export enum ERelationToApplicant {
  Spouse = 'SP',
  CommonLaw = 'CL',
  Employer = 'Employer',
  InLaw = 'IL',
  Parental = 'PR',
  GrandMother = 'GM',
  GrandFather = 'GF',
  Brother = 'B',
  Sister = 'S',
  Other = 'Other',
}

export const ERelationToApplicantList = Object.values(ERelationToApplicant)

export enum EProgressionStatus {
  Quotation = 'QT',
  Submitted = 'SU',
  AwaitingDecision = 'AD',
  CreditPending = 'CP',
  ConditionalApproval = 'CA',
  Approved = 'AP',
  Declined = 'DE',
  DocumentReceived = 'DR',
  DocumentIncomplete = 'DI',
  PaymentPending = 'PP',
  Booked = 'BK',
  WithDrawn = 'WD',
}
export const EProgressionStatusList = Object.values(EProgressionStatus)

export enum EExternalStep {
  BankAccount = 'bankAccount',
}

export enum EExternalStepStatus {
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Completed = 'completed',
}

export enum EFinancingRefusalApproveMessage {
  TaskIncomplete = 'taskIncomplete',
  NotAllIcomeConfirmed = 'notAllIcomeConfirmed',
  MissingRequiredDocument = 'missingRequiredDocument',
  NormRefusalMessage = 'normRefusalMessage',
  MileAgeOver999999 = 'mileAgeOver999999',
  CarfaxOver4999WithoutVehicleValueOrVehicleEvalutionDocument = 'carfaxOver4999WithoutVehicleValueOrVehicleEvalutionDocument',
  MissingVehicleEvalutionDocument = 'missingVehicleEvalutionDocument',
  MissingVehicleValueOrThirdPartyVehicleValue = 'missingVehicleValueOrThirdPartyVehicleValue',
  MissingExistingCustomerReference = 'missingExistingCustomerReference',
  DeliveryDateInPast = 'deliveryDateInPast',
}

export const EFinancingRefusalApproveMessageList = Object.values(EFinancingRefusalApproveMessage)

export enum ETermPersonalLoan {
  twelve = 12,
  twentyFour = 24,
  thirtySix = 36,
  fortyEight = 48,
  sixty = 60,
  seventyTwo = 72,
  eightyFour = 84,
}

export enum EPaymentPlan {
  regularDailyInterests = 'regularInterest',
  regularPeriodInterests = 'regularPeriod',
  deferredThreeMonths = 'deferred3',
}

export const EPaymentPlanList = Object.values(EPaymentPlan)

export enum EPaymentMethod {
  check = 'check',
  bankTransfer = 'bankTransfer',
  DEFT = 'DEFT',
  WIRE = 'Wire',
}

export const EPaymentMethodList = Object.values(EPaymentMethod)

export enum EBeneficiaryType {
  NotSet = 0,
  Applicant = 1,
  Coapplicant = 2,
  Other = 3,
}

export const EBeneficiaryTypeList = Object.values(EBeneficiaryType).filter((x) => typeof x === 'number')

export enum ELoanPurpose {
  Veterinary = 'veterinary',
  Medical = 'medical',
  Aesthetics = 'aesthetics',
  Dentistry = 'dentistry',
  GoodsAndServices = 'goodsAndServices',
}

export const ELoanPurposeList = Object.values(ELoanPurpose)

export enum EElectronicSignatureStatus {
  Pending = 'pending',
  CreateContract = 'createContract',
  StartSignatureProject = 'startSignatureProject',
  AwaitingSignature = 'awaitingSignature',
  CancelSignatureProject = 'cancelSignatureProject',
  Completed = 'completed',
  Cancel = 'cancel',
}

export enum EMerchantIds {
  IfinanceMerchantId = 'alis*20500',
  MedicardMerchantId = 'alis*20878',
  PayCustomerByBankTransfer = 'alis*451',
  PayCustomerByCheque = 'alis*286',
}

export enum EApplicantRefusalReason {
  ApplicantMustHaveCreditReport = 'ApplicantMustHaveCreditReport',
  CoapplicantMustHaveCreditReport = 'CoapplicantMustHaveCreditReport',
  IceScoreTooLow = 'IceScoreTooLow',
}

export enum ESourceSystem {
  Alis = 'alis',
  Monolith = 'monolith',
}

export enum EContinueFinancingDisableReason {
  MaximumAvalaibleAmountIsTooLow = 'maximumAvalaibleAmountIsTooLow',
  CreatedOnIsExpired = 'createdOnIsExpired',
  WorksheetIsNotApproved = 'worksheetIsNotApproved',
  CustomerNeedToBeCreated = 'customerNeedToBeCreated',
}

export enum EOriginSystemId {
  Backoffice = 'backoffice',
  MerchantDashboard = 'merchdb',
  Subscription = 'ifinancesub',
  B2c = 'b2csub',
  Autologiq = 'autologiq',
}

export const EOriginSystemIdList = Object.values(EOriginSystemId)

export type FinancingProgramConfig = {
  useSoftHit: boolean
  useConsigno: boolean
  requestedLoanAmountIsRequired: boolean
  useIFiScore?: boolean
  useIncomeSection?: boolean
  useExpenseSection?: boolean
  useSuggestedLoan?: boolean
  useCreditSection?: boolean
  useCreditDecisionFields?: boolean
  useCreditDecisionDialogFields?: boolean
  useCreditHardHit?: boolean
  useCreateAlisLoan: boolean
  useCoapplicant?: boolean
  noIncomeValidation: boolean
}

const defaultFinancingProgramConfig: FinancingProgramConfig = {
  useSoftHit: true,
  useConsigno: true,
  requestedLoanAmountIsRequired: true,
  useIFiScore: true,
  useIncomeSection: true,
  useExpenseSection: true,
  useSuggestedLoan: true,
  useCreditSection: true,
  useCreditDecisionFields: true,
  useCreditDecisionDialogFields: true,
  useCreditHardHit: true,
  useCreateAlisLoan: false,
  useCoapplicant: true,
  noIncomeValidation: false,
}

export const Constants = {
  GetMinIncomeForProgram: (program: EFinancingProgram) => {
    if (EFinancingProgram.Vlvr === program) return 20000
    return 21000
  },
  // Pour le moment, le program n'est pas utilisé, le if est présent pour enlever l'erreur
  GetMinCombinedIncomeForProgram: (program: EFinancingProgram) => {
    if (EFinancingProgram.Vlvr === program) return 35000
    return 35000
  },
  SupportedDocumentTypes: '.jpg, .jpeg, .png, .pdf, .heic, .heif, .webp',
  SystemUserId: 'system',
  MinimumLoanAmount: 500,
  MaximumLoanAmount: 40000,
  financingProgramConfigs: {
    [EFinancingProgram.Auto]: {
      ...defaultFinancingProgramConfig,
      useSoftHit: false,
      useConsigno: false,
      requestedLoanAmountIsRequired: false,
    },
    [EFinancingProgram.B2c]: {
      ...defaultFinancingProgramConfig,
      useCreateAlisLoan: true,
    },
    [EFinancingProgram.AutoRsla]: {
      ...defaultFinancingProgramConfig,
      useSoftHit: false,
      useConsigno: false,
      useCreditSection: false,
      useIncomeSection: false,
      useExpenseSection: false,
      useCoapplicant: false,
      requestedLoanAmountIsRequired: false,
      noIncomeValidation: true,
    },
    [EFinancingProgram.Personal]: {
      ...defaultFinancingProgramConfig,
      useCreateAlisLoan: true,
    },
    [EFinancingProgram.Products]: {
      ...defaultFinancingProgramConfig,
      useSuggestedLoan: false,
      useCreditDecisionFields: false,
      useCreditDecisionDialogFields: false,
      useCreditHardHit: false,
      noIncomeValidation: true,
    },
    [EFinancingProgram.IfXpress]: {
      ...defaultFinancingProgramConfig,
      useIFiScore: false,
      useIncomeSection: false,
    },
    default: {
      ...defaultFinancingProgramConfig,
      useSuggestedLoan: false,
      useCreditDecisionFields: false,
      useCreditDecisionDialogFields: false,
    },
  } as Record<EFinancingProgram | 'default', FinancingProgramConfig>,
}

// doit être fait en deux lignes sinon webpack optimise et n'inclut pas la string
// de plus, le toString est requis car en local c'est un string alors qu'en dev c'est une string
const useDevToolsStr = process.env.REACT_APP_USE_DEV_TOOLS ?? 'false'
export const useDevTools = useDevToolsStr.toString() === 'true'
